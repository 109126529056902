
































import Vue from 'vue';

interface Validation {
  [index: string]: string|null,
  message: string|null,
}

export default Vue.extend({
  props: {
    commissionId: {
      type: String,
      required: true,
    },
    businessId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      error: null,
      form: {
        message: '',
      },
      loading: false,
      validation: {
        message: null,
      } as Validation,
    };
  },
  methods: {
    async send(): Promise<void> {
      this.error = null;
      if (this.validate()) return;

      this.loading = true;

      const action = this.businessId
        ? 'business/commission/messages/Send'
        : 'commission/message/Create';

      await this.$store.dispatch(action, {
        COMMISSION_ID: this.commissionId,
        BUSINESS_ID: this.businessId,
        form: {
          message: this.form.message,
        },
      }).catch((e) => { this.error = e; });

      this.loading = false;

      if (!this.error) this.form.message = '';
    },
    validate(validation: Validation = {
      message: null,
    }): boolean {
      const { form: F } = this;
      const v: Validation = { ...validation };

      if (!F.message) {
        v.message = 'notOptional';
      } else if (F.message.length > 2000) {
        v.message = 'length';
      }

      this.validation = v;
      // Check if all keys of v are null, will return false(=!true)
      return !Object.keys(v).every((k) => v[k] === null);
    },
  },
});
