














import Vue from 'vue';
import { ApiResource } from '@/types';

export default Vue.extend({
  props: {
    message: {
      type: Object as () => ApiResource.CommissionMessage,
      required: true,
    },
    previousMessage: {
      type: Object as () => ApiResource.CommissionMessage,
      required: false,
      default: null,
    },
    nextMessage: {
      type: Object as () => ApiResource.CommissionMessage,
      required: false,
      default: null,
    },
    userId: {
      type: String,
      required: true,
    },
  },
});
