













import Vue from 'vue';
import { ApiResource } from '@/types';
import MessageComponent from '@/components/chat/Message.vue';

export default Vue.extend({
  components: {
    MessageComponent,
  },
  props: {
    messageList: {
      type: Array as () => ApiResource.CommissionMessage[],
      required: true,
    },
    // commissionId: {
    //   type: String,
    //   required: true,
    // },
    userId: {
      type: String,
      required: true,
    },
  },
});
