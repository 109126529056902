


























import Vue from 'vue';
import { ApiResource } from '@/types';
import MessageForm from '@/components/chat/MessageForm.vue';
import MessageList from '@/components/chat/MessageList.vue';

export default Vue.extend({
  components: {
    MessageForm,
    MessageList,
  },
  props: {
    messageList: {
      type: Array as () => ApiResource.CommissionMessage[],
      required: true,
    },
    businessId: {
      type: String,
      required: false,
      default: null,
    },
    commissionId: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },
});
