import { render, staticRenderFns } from "./Component.vue?vue&type=template&id=d6dd50a8&scoped=true&"
import script from "./Component.vue?vue&type=script&lang=ts&"
export * from "./Component.vue?vue&type=script&lang=ts&"
import style0 from "./Component.vue?vue&type=style&index=0&id=d6dd50a8&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6dd50a8",
  null
  
)

/* custom blocks */
import block0 from "./Component.en.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fcomponents%2Fchat%2FComponent.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports